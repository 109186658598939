import cartService from "~/service/cartService";
import customService from "@/service/customService";
import get from "lodash/get";
import set from "lodash/set";

export default {
  computed: {
    isPhisical() {
      return this.product?.productClasses?.some(
        classItem => classItem.description == "PRE_FI"
      );
    },
    isVirtual() {
      return this.product?.productClasses?.some(
        classItem => classItem.description == "PRE_VI"
      );
    },
    isDigital() {
      return this.product?.productClasses?.some(
        classItem => classItem.description == "PRE_DI"
      );
    },
    isPdvOnly() {
      return this.product?.productClasses?.some(
        classItem => classItem.description == "PRE_PDV"
      );
    }
  },
  methods: {
    async addToCart() {
      if (this.quantity <= this.product.available) {
        let productData = {
          store_id: 6,
          product: this.product,
          quantity: this.quantity
        };
        let res = await customService.addProductsToList([productData]);
        if (res) {
          this.$emit("addedToCart");
        }
      } else {
        global.EventBus.$emit("error", {
          message: global.EventBus.$t("errors.maxAcq")
        });
      }
    },
    async plus(updateCartParam, params, searchParams) {
      let updateCart =
        typeof updateCartParam == "boolean"
          ? updateCartParam
          : global.config.updateCart;

      let ignoreMultiplier =
        get(params, "infos.is_package", "undefined") === "false";
      let newQuantity = cartService.plus(
        this.product,
        this.quantity,
        ignoreMultiplier
      );
      if (newQuantity > this.product.available && this.product.available > 0) {
        newQuantity = this.product.available;
      }
      // let maxAcq = this.getMaxAcq(this.product);
      // if (newQuantity <= toNumber(maxAcq)) {
      if (updateCart) {
        if (this.quantity == 0) {
          //check if there are options to choose
          this.checkSelectOptionAndAdd(newQuantity, params, searchParams);
        } else {
          if (!ignoreMultiplier) {
            //nel caso standard, importano la quantità dell' item sottostante
            this.setItem({
              item: this.item,
              quantity: newQuantity,
              searchParams: searchParams
            });
          } else {
            //nel caso stiamo lavorando sui joltiplicatori:
            //se ho raggiunto la quantity del multiplier rimuovo e aggiungo con package
            let qtyMult = parseFloat(this.product.productInfos.QTY_MULT);
            if (newQuantity >= qtyMult) {
              this.removeItem(this.item);
              this.addProduct({
                product: this.product,
                quantity: newQuantity,
                params: set(params, "infos.is_package", "true"),
                searchParams: searchParams
              });
            } else {
              this.setItem({
                item: this.item,
                quantity: newQuantity,
                searchParams: searchParams
              });
            }
          }
        }
      } else {
        this.quantity = newQuantity;
      }
      // } else {
      //   global.EventBus.$emit("error", {
      //     message: global.EventBus.$t("errors.maxAcq")
      //   });
      // }
    }
  }
};
