<template>
  <!--Card is disabled if is not in the catalog section-->
  <v-card
    v-bind:product="product"
    outlined
    rounded="md"
    class="product-award-card-container product-card fill-height"
    ref="awardsCard"
    :class="item ? 'history-card' : 'get-award-card'"
    :id="`product-card-${product.slug}`"
    :disabled="awardDisabled"
    :to="{
      name: 'AwardsDetail',
      params: { slug: product.slug }
    }"
  >
    <div class="product px-0 d-flex flex-row flex-sm-column">
      <span
        class="award-tag rounded-sm primary"
        v-if="
          product?.productClasses?.some(
            classItem => classItem.productClassId === 10039
          )
        "
      >
        Premio in quantità limitata
      </span>
      <v-img
        class="rounded-md rounded-bl-0 rounded-br-0"
        cover
        width="170"
        height="170"
        :src="product.mediaURL"
        :alt="'Immagine' + product.slug"
      />

      <div class="description  d-flex flex-column">
        <span class="name font-weight-bold mb-2">
          {{ product.name }}
        </span>
        <span class="name mb-2">
          {{ product.shorDescr }}
        </span>

        <span
          v-if="product?.warehousePromo"
          class="points-information primary--text font-weight-bold"
          v-html="product.warehousePromo?.view.bubble"
        >
        </span>
        <span
          v-if="product?.variations && product?.variations[0]?.warehousePromo"
          class=""
          v-html="product.variations[0]?.warehousePromo.view.bubble"
        ></span>
        <div v-if="item">
          Data richiesta: <br />
          <strong>{{
            $dayjs(item.timeslotDate).format("DD MMMM - HH:MM")
          }}</strong>
        </div>
      </div>
    </div>

    <!-- Button for premi digitali-->
    <v-card-actions v-if="item">
      <v-btn
        v-if="isDigital"
        block
        large
        v-on:click.prevent="seeAward(item)"
        @click.stop="dialog = true"
        class="action-button elevation-0"
        color="primary"
        >{{ $t("awards.booked.seeCoupon") }}
        <v-dialog
          v-if="response"
          scrollable
          v-model="dialog"
          width="500"
          :fullscreen="$vuetify.breakpoint.mobile"
        >
          <v-card>
            <!-- <v-card-title>{{ item.product.name }}</v-card-title> -->
            <v-img
              height="200px"
              contain
              :src="getHandleImageSrc"
              onerror="this.onerror=null;this.src='/no-icon.png'"
            ></v-img>

            <v-card-text>
              <div class="voucher-detail" v-html="response.messaggio_pv" />
              <barcode
                v-bind:value="response.upc"
                class="barcode"
                format="CODE39"
              />
            </v-card-text>

            <v-card-actions class="voucher-close flex-column justify-end">
              <v-btn
                class="mb-3"
                block
                large
                depressed
                color="primary"
                @click="printPremi"
                >{{ $t("orders.button.print") }}</v-btn
              >
              <v-btn block large color="primary" outlined @click="closeDialog">
                {{ $t("common.close") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-btn>

      <!-- Button for physical premi-->
      <template v-if="!(isDigital || isVirtual || isPdvOnly)">
        <v-btn
          v-if="!item.logistic"
          block
          large
          v-on:click.prevent="cancelAward(item)"
          @click.stop="physicalDialog = true"
          class="action-button elevation-0"
          color="primary"
          >{{ $t("awards.booked.cancelCoupon") }}</v-btn
        >

        <v-btn
          v-if="item.status == 'Disponibile per il ritiro'"
          block
          large
          @click.stop.prevent="barcodeDialog = true"
          class="action-button elevation-0"
          color="primary"
        >
          Pronto per il ritiro
          <v-dialog v-model="barcodeDialog" width="350">
            <v-card class="w-100" style="height:300px">
              <v-card-title>{{ item.product.name }}</v-card-title>
              <v-card-text>
                Usa questo codice per ritirare il premio
              </v-card-text>

              <barcode v-bind:value="item.upc" class="barcode-wrapper" />
            </v-card>
          </v-dialog>
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>
<style lang="scss">
.product-award-card-container {
  .award-tag {
    position: absolute;
    z-index: 2;
    padding: 2px;
    top: -10px;
    color: white;
    font-weight: bold;
  }
  .v-image {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      width: 100px;
      height: 100px;
    }
  }
  .product {
    .description {
      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        width: 100%;
      }
      height: 100px;
      padding: 0 8px;
      .points-information {
        strong {
          margin-left: 5px;
        }
        //what it does: hides the carta fedelta image that comes from server
        span.card {
          display: none;
        }
      }
    }
  }
  .product .actions {
    min-height: 42px;
  }
  &.history-card {
    .description {
      height: 112px;
      margin-bottom: 12px;
    }
  }
}
.title {
  display: flex;
  flex-direction: column;
}
@media print {
  .profile-container .booker-awards-container,
  footer .container,
  footer,
  .voucher-close {
    display: none;
  }
}
</style>
<script>
import productMixin from "~/mixins/product";
import awardsMixin from "@/mixins/awards";
import customService from "@/service/customService";
import VueBarcode from "vue-barcode";
import { mapGetters } from "vuex";
import CartService from "~/service/cartService";
export default {
  name: "AwardsCard",
  components: {
    barcode: VueBarcode
  },
  props: {
    item: { type: Object, required: false },
    product: { type: Object, required: true },
    upc: { type: Number, required: false },
    reversable: { type: Boolean, required: false },
    bookedDate: { type: String, required: false },
    buyDate: { type: String, required: false }
  },
  mixins: [productMixin, awardsMixin],
  data() {
    return {
      dialog: false,
      response: null,
      loading: true,
      products: []
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    }),
    handleImageSrc() {
      return (
        ("data:image/jpg;base64," + this.product &&
          this.product.imageBase64 &&
          this.product.imageBase64.trim()) ||
        this.product.mediaURL
      );
    },
    awardDisabled() {
      return this.product.codInt == -1;
    },
    getHandleImageSrc() {
      return (
        ("data:image/jpg;base64," + this.product &&
          this.product.imageBase64 &&
          this.product.imageBase64.trim()) ||
        this.product.mediaURL
      );
    }
  },
  methods: {
    async plus() {
      this.quantity = CartService.plus(this.product, this.quantity);
    },
    minus() {
      this.quantity = CartService.minus(this.product, this.quantity);
    },
    async seeAward() {
      try {
        this.loading = true;
        let result = await customService.renderAwards(
          this.item.upc,
          this.product.upc,
          this.user.fidelityCard
        );
        if (result) {
          this.response = result;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    closeDialog() {
      this.dialog = false;
      this.response = null;
    },
    printPremi() {
      if (this.isCordova) {
        window.cordova.plugins.printer.print();
      } else {
        window.print();
      }
    },
    async cancelAward() {
      try {
        let res = await this.$dialog.confirm({
          text: global.vm.$t(
            `Sei sicuro di voler stornare il premio? <br/><span class='font-weight-bold'>${this.product.name}</span>`
          )
        });
        if (res) {
          let upcString = this.item.upc.toString();
          let result = await customService.cancelAwards(
            upcString,
            this.item.timeslotDate,
            this.item.shippingAddress.addressId
          );
          if (result) {
            this.$emit("awardsCanceled");
            this.response = result;
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>
